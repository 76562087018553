// This is a reusable dialog box, so I can add any type of content I want to it later

import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import React from 'react';

type DialogProps = {
  children: React.ReactNode;
  closeModal: () => void;
  openModal: boolean;
  modalTitle: string;
};

export default function Modal({
  children,
  closeModal,
  openModal,
  modalTitle,
}: DialogProps) {
  return (
    <Transition appear show={openModal} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={closeModal}>
        <div className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto">
          <div className="p-5 flex h-screen justify-center items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="bg-white w-full p-5 max-w-2xl rounded-lg grid gap-5">
                {/* Header and Button to close modal */}
                <div className="w-full flex gap-5 justify-between items-center">
                  <h2 className="text-xl md:text-2xl text-crunchies-red">
                    {modalTitle}
                  </h2>
                  <button
                    type="button"
                    onClick={closeModal}
                    className="outline-none border border-gray-200 hover:bg-gray-200 rounded-full p-1"
                  >
                    <XMarkIcon className="w-6" />
                  </button>
                </div>

                {/* Content */}
                <div className="grid gap-8 pb-5 max-h-96 overflow-y-auto">
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
